<template>
  <v-form ref="formPassword" v-model="isValid" lazy-validation>
    <v-row>
      <v-col cols="12" style="margin: auto">
        <p class="text-recover">
          <span class="extra-bold">RECUPERAÇÃO DE SENHA</span>
        </p>
        <p class="subtext-recover mt-4">Cadastre uma nova senha</p>
      </v-col>

      <v-col cols="12" class="pb-0">
        <div class="field-description"><span>SENHA*</span></div>
        <v-text-field
          v-model="formDataRecover.password"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="show1 = !show1"
          :rules="[rules.required, rules.passwordRules]"
          :type="show1 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          required
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0 mb-5">
        <div class="field-description"><span>SENHA*</span></div>

        <v-text-field
          v-model="formDataRecover.confirmationPassword"
          
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="show2 = !show2"
          :rules="[
            rules.required,
            rules.passwordRules,
            rules.passwordMatch(
              formDataRecover.password,
              formDataRecover.confirmationPassword
            ),
          ]"
          :type="show2 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          required
          variant="outlined"
          color="#0e0d5e"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="3" style="text-align: center" class="pt-0">
        <button v-if="!loading" @click="newPassword()" class="button-next">
          <div class="text-wrapper">CONTINUAR</div>
        </button>
        <v-progress-circular
          v-else-if="loading"
          indeterminate
          color="#0D3A50"
          :size="33"
          class="loading-spinner"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "../../utils/rules";
import { showNotificationError } from "@/services/notifications";
export default {
  data() {
    return {
      rules: rules,
      show1: false,
      show2: true,
    };
  },
  computed: {
    ...mapState("recover", ["formDataRecover", "loading"]),
    ...mapFields("recover", ["formDataRecover.token"]),
    ...mapState("login", ["userLogged"]),
  },
  components: {
    // Spinner,
  },
  methods: {
    ...mapActions("recover", ["setPassword"]),

    async newPassword() {
      await this.$refs.formPassword.validate();
      if (this.isValid) {
        this.setPassword(this.$router);
      } else {
        showNotificationError("Preencha o campo de código corretamente");
      }
    },
  },
};
</script>
<style src="../../styles/Recover.css"></style>