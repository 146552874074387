import { validateCPF } from './validation.js'

export default {
  required: (value) => !!value || "Obrigatório.",
  min: (v) => v.length >= 8 || "Min 8 characters",
  email: (value) => /.+@.+\..+/.test(value) || 'Email inválido.',
  emailMatch: () => `The email and password you entered don't match`,
  cpfValid: (value) => validateCPF(value) || "CPF inválido",
  alreadyExists: (loginInUse) => !loginInUse || "Usuário já cadastrado",
  passwordMatch: (password, confirmationPassword) => password === confirmationPassword || 'As senhas não coincidem',
  dayValid: v => (v && v > 0 && v <= 31) || 'Dia inválido',
  yearValid: v => (v && v.length === 4 && v > 1900 && v <= new Date().getFullYear()) || 'Ano inválido',
  monthValid: v => (v && v > 0 && v <= 12) || 'Mês inválido',
  passwordRules: (value) => {
    if (!value) return "Obrigatório.";
    if (value.length < 6) return "A senha deve ter pelo menos 6 caracteres.";
    if (value.length > 16) return "A senha deve ter no máximo 16 caracteres.";
    if (!/^[a-zA-Z0-9]+$/.test(value)) return "A senha só pode conter letras e números.";
    return true;
  },
  usernameValid: (v) =>
    /^[a-zA-Z0-9]+$/.test(v) || "O usuário não pode ter espaços ou caracteres especiais.",
};
