<template>
  <v-container class="form-container mb-14" fluid>
    <v-card class="form-card px-7">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="12" class="ma-0 pl-0 pr-0 pb-1">
          <v-card-title class="form-header">
            <v-btn icon @click="goBack" class="back-button">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <StepperComponent :step="currentStep" class="small-stepper" />
            <v-spacer></v-spacer>
            <v-btn icon @click="closeModal" class="close-button">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-0 ml-1">
        <v-col cols="12" md="10" lg="12" class="pt-0 mt-0">
          <p class="form-content-instruction pb-0" v-html="instructionText()"></p>
        </v-col>
      </v-row>
      <v-card-text class="form-content pb-0 pt-0 mt-0">
        <StepsFieldComponent :step="currentStep" ref="currentStepForm" />
      </v-card-text>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="12" >
          <v-card-actions class="button-margin pt-0 mt-0 pb-0">
            <button v-if="!loadingStep" @click="nextStep()" class="button-next mt-2 ">
              <div class="text-wrapper">PRÓXIMO</div>
            </button>

            <v-progress-circular
              v-else
              indeterminate
              :size="42"
              :width="4"
              color="#0D3A50"
            />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>


<script>
import StepsFieldComponent from "@/components/register/StepsFieldsComponent.vue";
import StepperComponent from "@/components/StepperComponent.vue";
import { mapState, mapActions, mapMutations } from "vuex";
// import Spinner from "@/components/SpinnerLoading.vue";

export default {
  props: {
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  components: {
    StepsFieldComponent,
    StepperComponent,
    // Spinner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("form", [
      "formDataStep1",
      "formDataStep2",
      "currentStep",
      "loadingStep",
    ]),
  },
  methods: {
    ...mapActions("form", ["saveStep", "buyChips"]),
    ...mapMutations("form", ["SET_CURRENT_STEP"]),
    async nextStep() {
      if (await this.validateCurrentStep()) {
        this.saveStep(this.$router);
      }
    },
    async validateCurrentStep() {
      console.log("validateCurrentStep");
      if (this.$refs.currentStepForm) {
        console.log("validateCurrentStep 2");
        let valid = await this.$refs.currentStepForm.validate();
        console.log("valid", valid);
        return valid;
      }
      console.log("Erro ao validar formulário");
      return false;
    },
    instructionText() {
      switch (this.currentStep) {
        case 1:
          return "Fala aí, jogador! Que bom que você decidiu participar do nosso clube! Falta pouco para se tornar um jogador XTREME. Preencha seus dados para continuar.";
        case 2:
          return "Para dar continuidade ao seu cadastro insira, por favor, seu e-mail, nome de usuário e escolha uma senha.";
        case 3:
          return "<strong class='text-step-3' >VERIFICAÇÃO DE EMAIL</strong><br><span class='sub-text-step-3' >Digite os 4 números enviados para o e-mail cadastrado!</span>";
        default:
          return "";
      }
    },
    closeModal() {
      this.SET_CURRENT_STEP(1);
      this.$router.push("/register");
    },
    goBack() {
      if (this.currentStep > 1) {
        this.SET_CURRENT_STEP(this.currentStep - 1);
      }
    },
   
  },
};
</script>

<style src="../../styles/Form.css"></style>

