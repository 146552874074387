<template>
  <v-form ref="step2Form">
    <v-row>
      <v-col cols="12" class="pb-0 small-col">
        <div class="field-description"><span>EMAIL*</span></div>
        <v-text-field v-model="formDataStep2.email" placeholder="Digite aqui seu email" required
          :rules="[rules.required, rules.email]" variant="outlined" prepend-inner-icon="mdi-email"
          color="#0e0d5e"></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0 small-col">
        <div class="field-description"><span>LOGIN*</span></div>
        <v-text-field ref="usernameField" v-model="formDataStep2.username" placeholder="Escolha o seu nome de usuário"
          :rules="[rules.required, rules.usernameValid, rules.alreadyExists(loginInUse)]" prepend-inner-icon="mdi-login" required
          @input="checkLoginInUse" variant="outlined" color="#0e0d5e"></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-0 small-col">
        <div class="field-description"><span>SENHA*</span></div>
        <v-text-field v-model="formDataStep2.password" placeholder="Entre 6 e 16 caracteres"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="show1 = !show1"
          :rules="[rules.required, rules.passwordRules]" :type="show1 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock" required variant="outlined" color="#0e0d5e"
          ></v-text-field>
      </v-col>

      <!-- <v-list dense class="password-requirements">
        <v-list-item>
          <v-icon :color="passwordLengthValid ? 'green' : 'red'">{{ passwordLengthValid ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
          <span>Tamanho entre 6 e 16 caracteres</span>
        </v-list-item>
        <v-list-item>
          <v-icon :color="passwordCharsValid ? 'green' : 'red'">{{ passwordCharsValid ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
          <span>Apenas letras e números</span>
        </v-list-item>
      </v-list> -->

      <v-col cols="12" class="pb-0 small-col">
        <div class="field-description"><span>SENHA*</span></div>

        <v-text-field v-model="formDataStep2.confirmationPassword"  placeholder="Entre 6 e 16 caracteres"
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="show2 = !show2" :rules="[
            rules.required,
            rules.passwordRules,
            rules.passwordMatch(
              formDataStep2.password,
              formDataStep2.confirmationPassword
            ),
          ]" :type="show2 ? 'text' : 'password'" prepend-inner-icon="mdi-lock" required variant="outlined"
          color="#0e0d5e"></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rules from "../../utils/rules";
import { showNotificationError } from "@/services/notifications/index.js";

export default {
  data() {
    return {
      show1: false,
      show2: true,
      rules: rules,

    };
  },
  computed: {
    ...mapState("form", ["formDataStep2", "loginInUse"]),
  },
  methods: {
    ...mapActions("form", ["setLoginInUse"]),
    async validate() {
      let passwordMatch =
        this.formDataStep2.password === this.formDataStep2.confirmationPassword;
      if (passwordMatch) {
        return await this.$refs.step2Form.validate();
      }
      showNotificationError("As senhas não conferem");
      return false;
    },
    
    checkLoginInUse() {
      this.setLoginInUse(false);
    },
  },
  mounted() {
    if (this.loginInUse) {
      this.$refs.usernameField.validate();
    }
  },
};
</script>
<style src="../../styles/Steps.css"></style>